import React from "react"
import styles from "./Sections.module.scss"
import { Col } from "react-flexbox-grid"
import { Row } from "react-flexbox-grid"

const SectionDetailsHighlight = ({
  title,
  img,
  img_alt,
  excerpt,
  tag,
  alt,
  last,
}) => (
  <Col md={12} xs={12} className={styles.sectionDetail}>
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Row between="xs" middle="xs">
            <Col xs={12} sm={8} md={5} last={last}>
              <div className={styles.sectionImage}>
                <img className={styles.featureImage} src={img} alt={img_alt} />
              </div>
            </Col>
            <Col xs={12} sm={8} md={5}>
              <h3>{title}</h3>
              <p>{excerpt}</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </Col>
)

export default SectionDetailsHighlight
