import React from "react"
import styles from "./Sections.module.scss"
import { Col } from "react-flexbox-grid"

const SectionDetails = ({ title, img, img_alt, excerpt }) => (
	<Col md={5} xs={12} className={styles.sectionDetail}>
		<div className={styles.sectionImage}>
			<img className={styles.featureImage} src={img} alt={img_alt} />
		</div>
		<h3>{title}</h3>
		<p>{excerpt}</p>
	</Col>
)

export default SectionDetails
