import React from "react"
import styles from "./Partners.module.scss"
import { Row, Col } from "react-flexbox-grid"
import CarouselDetails from "./CarouselDetails"

export default () => (
  <div className={styles.content}>
    <div className={styles.container}>
      <Row around="xs" middle="xs" className={styles.featuresBox}>
        <Col xs={10} sm={5} md={4} lg={3}>
          <h3>Customers and Partners</h3>
          <p>Join the list of companies using UpdateHub</p>
        </Col>
        <Col xs={10} sm={9} md={8} lg={7}>
          <CarouselDetails />
        </Col>
      </Row>
    </div>
  </div>
)
