import React from "react"
import styles from "./Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"

export default () => (
  <section className={styles.section}>
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Row around="xs">
            <Col xs={12} sm={8} md={5}>
              <h1>
                The end-to-end solution for massive over-the-air update of
                devices
              </h1>
              <p>
                UpdateHub is an enterprise-grade solution which makes simple to
                remotely update all your Linux-based devices in the field, with
                maximum security and efficiency, while you focus in adding value
                to your product.
              </p>
              <h2>All your devices, always updated</h2>
            </Col>
            <Col xs={12} sm={8} md={5}>
              <iframe
                className={styles.video}
                src="https://www.youtube.com/embed/GQgCNZib030"
                title="updateHub"
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <div className={styles.frame} />
  </section>
)
