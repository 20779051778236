import React from "react"
import styles from "./Pricing.module.scss"
import { Row } from "react-flexbox-grid"
import NavButton from "../Buttons/NavButton"
import PricingDetails from "./PricingDetails"

export default () => (
  <div className={styles.content}>
    <div className={styles.container}>
      <h1>Pricing</h1>
      <Row center="xs" middle="xs" className={styles.pricingRow}>
        <PricingDetails
          title="DEVELOPER"
          label="Free"
          styleLabel={styles.free}
          description="No credit card required."
        />
        <PricingDetails
          title="ORGANIZATION"
          label="Metered billing"
          styleLabel={styles.meteredBilling}
          description="Starting at $10 / month"
        />
      </Row>
      <div className={styles.button}>
        <NavButton text={"PRICING DETAILS"} link={"/pricing/#pricing"} />
      </div>
    </div>
  </div>
)
