import React from "react"
import styles from "./Resources.module.scss"
import { Row } from "react-flexbox-grid"
import ResourcesDetails from "./ResourcesDetails"

export default () => (
  <div className={styles.content}>
    <div className={styles.container}>
      <Row center="xs" middle="xs" className={styles.rowBox}>
        <ResourcesDetails
          link="https://docs.updatehub.io"
          title="Documentation"
          img="/imgs/doc.svg"
          styleImg={styles.logoImg}
        />
        <ResourcesDetails
          link="https://gitter.im/UpdateHub/community"
          title="Gitter"
          img="/imgs/gitter.svg"
          styleImg={styles.logoImg}
        />
        <ResourcesDetails
          link="https://github.com/UpdateHub"
          title="Projects"
          img="/imgs/github.svg"
          styleImg={styles.logoImg}
        />
        <ResourcesDetails
          link="https://github.com/UpdateHub/updatehub-ce"
          title="UpdateHub CE"
          img="/imgs/uphub.svg"
        />
      </Row>
    </div>
  </div>
)
