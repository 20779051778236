import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styles from "./Sections.module.scss"
import SectionDetails from "./SectionDetails"
import SectionDetailsHighlight from "./SectionDetailsHighlight"
import { Row } from "react-flexbox-grid"

export default () => (
  <>
    <div className={styles.sectionHeader}>Main UpdateHub benefits:</div>
    <div className={styles.featureSection}>
      <div className={styles.container}>
        <Row between="xs" middle="xs">
          <StaticQuery
            query={graphql`
              query {
                allMarkdownRemark(
                  filter: { fileAbsolutePath: { regex: "/sections/" } }
                  sort: { fields: frontmatter___order, order: ASC }
                ) {
                  edges {
                    node {
                      frontmatter {
                        title
                        img
                        img_alt
                        highlight
                        last
                      }
                      excerpt(pruneLength: 500)
                    }
                  }
                }
              }
            `}
            render={data =>
              data.allMarkdownRemark.edges.map(({ node }) => {
                if (node.frontmatter.highlight === "true") {
                  return (
                    <SectionDetailsHighlight
                      key={node.frontmatter.title}
                      title={node.frontmatter.title}
                      img={node.frontmatter.img}
                      img_alt={node.frontmatter.img_alt}
                      excerpt={node.excerpt}
                      highlight={node.highlight}
                      last={node.frontmatter.last}
                    />
                  )
                } else {
                  return (
                    <SectionDetails
                      key={node.frontmatter.title}
                      title={node.frontmatter.title}
                      img={node.frontmatter.img}
                      img_alt={node.frontmatter.img_alt}
                      excerpt={node.excerpt}
                    />
                  )
                }
              })
            }
          />
        </Row>
      </div>
    </div>
  </>
)
