import React from "react"
import styles from "./Features.module.scss"
import FeaturesCol from "./FeaturesCol"

export default ({ title, img }) => (
  <FeaturesCol>
    <div className={styles.icon}>
      <img src={img} alt={title} />
    </div>
    <h1>{title}</h1>
  </FeaturesCol>
)
