import React from "react"
import FeaturesCol from "./FeaturesCol"
import NavButton from "../Buttons/NavButton"

export default ({ text, link, label }) => (
  <FeaturesCol>
    <p>{text}</p>
    <NavButton text={label} link={link} />
  </FeaturesCol>
)
