import React from "react"
import styles from "./Resources.module.scss"
import { Col } from "react-flexbox-grid"

export default ({ link, title, img, styleImg }) => (
  <Col xs={10} sm={7} md={5} lg={3}>
    <a href={link} rel="noopener noreferrer" target="_blank">
      <div className={styles.resourcesBox}>
        <img src={img} alt={`link to ${title}`} className={styleImg} />
        <h1>{title}</h1>
      </div>
    </a>
  </Col>
)
