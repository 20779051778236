import React from "react"
import styles from "./Pricing.module.scss"
import { Col } from "react-flexbox-grid"

export default ({ title, label, styleLabel, description }) => (
  <Col xs={10} sm={7} md={5} lg={3}>
    <div className={styles.pricingDiv}>
      <h2>{title}</h2>
      <div className={styles.line} />
      <span className={styleLabel}>{label}</span>
      <p>{description}</p>
    </div>
  </Col>
)
