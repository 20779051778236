import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-flexbox-grid"
import styles from "./Depoiments.module.scss"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/depoiments/" } }
        ) {
          edges {
            node {
              frontmatter {
                title
                avatar
                company
                company_image
                author
                author_signature
              }
              excerpt(pruneLength: 250)
            }
          }
        }
      }
    `}
    render={data =>
      data.allMarkdownRemark.edges.map(({ node }) => (
        <section
          key={node.frontmatter.title}
          className={styles.depoimentsSection}
          id="depoiments"
        >
          <div className={styles.container}>
            <Row start="xs" middle="xs">
              <Col xs={12}>
                <Row start="xs" middle="xs">
                  <Col
                    mdOffset={1}
                    md={4}
                    middle="xs"
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <div className={styles.avatar}>
                      <a
                        href="https://www.ossystems.com.br/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          className={styles.avatarImg}
                          src={node.frontmatter.avatar}
                          alt={node.frontmatter.author}
                        />
                      </a>
                    </div>
                    <div className={styles.companyLogoBox}>
                      <a
                        href="https://www.ossystems.com.br/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        <img
                          src={node.frontmatter.company_image}
                          alt={node.frontmatter.company}
                        />
                      </a>
                    </div>
                  </Col>
                  <Col md={6} xs={12}>
                    <p className={styles.depoiment}>{node.excerpt}</p>
                    <div className={styles.depoimentSignature}>
                      <span>
                        <b>{node.frontmatter.author}</b>
                      </span>
                      <br />
                      <span>{node.frontmatter.author_signature}</span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
      ))
    }
  />
)
