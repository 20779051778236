import React from "react"
import styles from "./Features.module.scss"
import { Row } from "react-flexbox-grid"
import FeaturesIcon from "./FeaturesIcon"
import FeaturesDetails from "./FeaturesDetails"

export default () => (
  <div className={styles.content}>
    <div className={styles.container}>
      <Row center="xs" middle="xs" className={styles.featuresBox}>
        <FeaturesIcon title="Atomic Updates" img="/imgs/features/Atomic.png" />
        <FeaturesIcon
          title="Secure TLS communication between client/server"
          img="/imgs/features/SecureTLS.png"
        />
        <FeaturesIcon
          title="Integrity checksum"
          img="/imgs/features/Integrity.png"
        />
        <FeaturesDetails
          text="Many reliable features that will boost your product update process in no time"
          label="SEE ALL FEATURES"
          link="/features/#features"
        />
      </Row>
    </div>
  </div>
)
