import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import styles from "./Partners.module.scss"

export default () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 544 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 544, min: 0 },
      items: 1,
    },
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/partners/" } }
            sort: { fields: frontmatter___alt, order: ASC }
          ) {
            edges {
              node {
                frontmatter {
                  alt
                  src
                  pdf
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Carousel
          className={styles.boxCarousel}
          itemClass={styles.boxItem}
          responsive={responsive}
          arrows={false}
          infinite
          showDots
          autoPlay
        >
          {data.allMarkdownRemark.edges.map(({ node }) => {
            const pdfLink =
              node.frontmatter.pdf !== null ? (
                <a
                  className={styles.pdfLink}
                  href={node.frontmatter.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  case study
                </a>
              ) : null

            return (
              <div key={node.frontmatter.alt} className={styles.boxImg}>
                {pdfLink}
                <a href="/cases/#cases">
                  <img src={node.frontmatter.src} alt={node.frontmatter.alt} />
                </a>
              </div>
            )
          })}
        </Carousel>
      )}
    />
  )
}
