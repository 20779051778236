import React from "react"
import Layout from "../components/Layout.js"
import Sections from "../components/index/Sections.js"
import Hero from "../components/index/Hero.js"
import Depoiments from "../components/index/Depoiments.js"
import Features from "../components/index/Features.js"
import Pricing from "../components/index/Pricing"
import Resources from "../components/index/Resources.js"
import FooterContact from "../components/index/FooterContact.js"
import Footer from "../components/index/Footer.js"
import Partners from "../components/index/Partners.js"

export default () => (
  <Layout>
    <Hero />
    <Sections />
    <Features />
    <Pricing />
    <Depoiments />
    <Resources />
    <Partners />
    <FooterContact />
    <Footer />
  </Layout>
)
